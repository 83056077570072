 @mixin volunteers-style($size) {
   font-weight: 600;
   font-size: $size;
   padding-bottom: 18px;
 }

 .section__volunteers {
   background-color: #ececec;
   padding: 1% 7%;

   h1 {
     font-weight: normal;
     font-size: 60px;
   }

   .title__separator {
     width: 420px;
     height: 1px;
     background-color: black;
     margin-bottom: 40px;
   }

   .volunteers {
     display: flex;
     justify-content: space-between;

     .left__volunteers {

       // width: 39%;
       .volunteer {
         @include volunteers-style(19px);
       }
     }

     .middle__volunteers {
       .volunteer {
         // margin-right: 50px;
         @include volunteers-style(19px);
       }
     }

     .right__volunteers {
       .volunteer {
         @include volunteers-style(19px);
       }
     }
   }
 }

 @media only screen and (min-width: 320px) and (max-width: 480px) {
   .section__volunteers {
     h1 {
       font-size: 30px;
     }

     .title__separator {
       width: 220px;
     }

     .volunteers {
       flex-wrap: wrap;

       .left__volunteers {
         width: 80%;

         .volunteer {
           @include volunteers-style(17px);
         }
       }

       .middle__volunteers {
         .volunteer {
           @include volunteers-style(17px);
         }
       }

       .right__volunteers {
         .volunteer {
           @include volunteers-style(17px);
         }
       }
     }
   }
 }