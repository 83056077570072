.donation__popup__container {
  background-color: #f5f5f7;
  width: 300px;
  height: 430px;
  display: flex;
  flex-direction: column;
  color: #505050;
  margin: 0 auto;
  margin-top: 130px;
  border-radius: 6px;
  .x-wrapper {
    display: flex;
    justify-content: space-between;
    color: #c0c0c0;
  }
  .popup_header {
    padding: 4%;
    text-align: center;
    background-color: #e8e9eb;
    border-radius: 6px;
    .hands_logo {
      border-radius: 50%;
      width: 70px;
      height: 70px;
      border: 3px solid white;
      margin-left: 40%;
      margin-top: -14%;
    }
    .popup_title {
      margin-bottom: 1px;
      font-size: 17px;
      font-weight: 700;
      color: #000;
      text-shadow: 0 1px 0 #fff;
    }
    .popup_subtitle {
      margin-top: 1px;
      font-weight: 500;
      font-size: 13px;
      color: #5b5b65;
      text-shadow: 0 1px 0 #fff;
    }
  }
  .form_wrapper {
    border-top: 1px solid #d3d3d3;
    background-color: #f5f5f7;
    display: flex;
    align-items: center;
    flex-direction: column;
    form {
      width: 150px;
      padding: 6% 1%;
      margin-bottom: 3%;
      display: flex;
      flex-direction: column;
      label {
        font-weight: 500;
        font-size: 17px;
        text-align: center;
        margin-right: 5px;
        .form-check-input {
          margin-right: 10px;
        }
      }
      .amount_input {
        width: 90px;
        height: 30px;
        padding: 12px 20px;
        font-size: 16px;
        text-align: center;
      }
      .input_wrapper {
        display: flex;
      }
    }
    .donate_button {
      .StripeCheckout {
        width: 250px;
        font-size: 17px;
        height: 37px;
        font-weight: 700;
        border: 1px solid rgba(46, 86, 153, 0.1) !important;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
        color: #fff;
        background-image: linear-gradient(-180deg, #44b1e8, #3098de) !important;
      }
    }
  }
}

// mobile
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .donation__popup__container {
    margin-top: 15px;
    height: 480px;
    .popup_header {
      .hands_logo {
        margin-bottom: 3%;
      }
      .popup_title {
        margin-top: 4%;
      }
      .popup_subtitle {
        margin-bottom: 4%;
      }
    }
    .form_wrapper {
      form {
        padding: 0px;
        .form-check {
          height: 40px;
        }
        .input_wrapper {
          margin-top: 6%;
          .amount_input {
            border: 1px solid #d3d3d3;
            margin-top: -30px;
          }
        }
      }
    }
  }
}

.thankyou__popup__container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px;
  text-align: center;
}
