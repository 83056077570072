@mixin press-line-separator($width) {
  width: $width;
  height: 1px;
  background-color: black;
  margin-bottom: 40px;
}
.container__press__section {
  background-color: #ececec;
  padding: 1% 7%;
  h1 {
    font-size: 50px;
    font-weight: normal;
  }
  .press__line__separator {
    @include press-line-separator((380px));
  }
  .press__tiles__section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 2%;
  }
  .press__footer__section {
    .press__line__separator {
      @include press-line-separator(100%);
    }
    a {
      display: flex;
      text-decoration: none;
      justify-content: center;
      button {
        background-color: #46c2ff;
        color: white;
        font-weight: bold;
        padding: 25px 30px;
        font-size: 23px;
        border-radius: 10px;
        border-color: black;
        cursor: pointer;
        border: none;
        margin-bottom: 15px;
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 800px) {
  .container__press__section {
    padding-bottom: 30px;
    h1 {
      font-size: 40px;
    }
    .press__line__separator {
      @include press-line-separator((100%));
    }
    .press__footer__section {
      .press__line__separator {
        @include press-line-separator(100%);
      }
      a {
        display: flex;
        text-decoration: none;
        justify-content: center;
        button {
          background-color: #46c2ff;
          color: white;
          font-weight: bold;
          padding: 20px 25px;
          font-size: 22px;
          border-radius: 10px;
          border-color: black;
          cursor: pointer;
          border: none;
          margin-bottom: 15px;
        }
      }
    }
  }
}
