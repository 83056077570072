@mixin separator-line {
  width: 360px;
  height: 1px;
  background-color: gray;
  margin-bottom: 30px;
}

@mixin title-style {
  text-decoration: underline;
  font-weight: 500;
  color: blue;
  font-size: 50px;
  margin-top: 0px;
}
.container__orgs {
  background-color: #f4f4f4;
  padding-left: 7%;
  display: flex;
  height: 400px;
  .section__join__us {
    padding-top: 50px;
    width: 35%;
    padding-right: 30px;
    h1 {
      @include title-style();
      a {
        color: #006aca;
      }
    }
    .separator__line {
      @include separator-line();
    }
    p {
      font-size: 20px;
    }
  }
  .section__sponsors {
    background-color: white;
    width: 70%;
    padding-top: 50px;
    padding-left: 3%;
    .separator__line {
      @include separator-line();
    }
    h1 {
      @include title-style();
      a {
        color: #006aca;
      }
    }
    .container__sponsors {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 7%;
      .sponsor__box {
        background-color: white;
        .sponsor__logo {
          width: 130px;
          height: 130px;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);
          vertical-align: middle;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
            height: auto;
            padding: 5%;
          }
        }
        .sponsor__name {
          width: 130px;
          text-align: center;
          margin-top: 10px;
          height: 20px;
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 800px) {
  .container__orgs {
    flex-direction: column;
    height: auto;
    padding-bottom: 50px;
    .section__join__us {
      padding-top: 50px;
      width: 100%;
      padding-right: 30px;
      .separator__line {
        @include separator-line();
        width: 100%;
      }
      h1 {
        @include title-style();
      }
      p {
        font-size: 22px;
        line-height: 35px;
      }
    }
    .section__sponsors {
      background-color: #f4f4f4;
      width: 106%;
      padding-top: 50px;
      margin-left: -25px;
      padding-right: 7%;
      padding-left: 7%;
      .separator__line {
        @include separator-line();
        width: 300px;
      }
      h1 {
        @include title-style();
      }
      .container__sponsors {
        justify-content: flex-start;
        flex-wrap: wrap;
        .sponsor__box {
          background-color: #f4f4f4;
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          .sponsor__logo {
            background-color: white;
            margin-right: 20px;
          }
          .sponsor__name {
            background-color: #f4f4f4;
            text-align: left;
          }
        }
      }
    }
  }
}
