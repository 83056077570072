.container__menu {
  height: 140px;
  max-height: 160px;
  padding-left: 7%;
  .logo__dgac {
    width: 210px;
    position: absolute;
    z-index: 2;
    img {
      margin-top: 30px;
      width: 100%;
      height: auto;
      cursor: pointer;
    }
  }
  .menu__text {
    position: relative;
    text-align: right;
    padding-top: 30px;
    padding-right: 50px;
    font-size: 22px;
    font-weight: bold;
    z-index: 1;
    a {
      text-decoration: none;
      color: #929292;
    }
    .logo__ca img {
      width: 280px;
    }
  }
}

@media only screen and (min-width: 481px) and (max-width: 820px) {
  .container__menu {
    height: 140px;
    max-height: 140px;
    padding: 30px 1% 0;
    .logo__dgac {
      width: 190px;
      img {
        margin-top: 45px;
      }
    }
    .menu__text {
      position: relative;
      text-align: right;
      padding-top: 30px;
      padding-right: 50px;
      font-size: 18px;
      .logo__ca img {
        width: 250px;
      }
    }
  }
}

// mobile
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .container__menu {
    padding: 30px 1% 0;
    height: 120px;
    display: flex;
    box-shadow: 2px 2px 5px #999999;
    flex-direction: column;
    align-items: flex-start;
    .logo__dgac {
      width: 120px;
      margin-left: 20px;
      margin-top: -30px;
      margin-bottom: 5px;
      img {
        width: 100%;
        height: auto;
      }
    }
    .menu__text {
      a {
        color: gray;
      }
      .logo__ca img {
        width: 160px;
      }
      padding-top: 0px;
      padding-right: 5px;
      margin-left: 54%;
      font-size: 13px;
      font-weight: bold;
    }
  }
}
