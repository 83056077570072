.dealers_wrapper {
  background-color: white;
  padding-left: 7%;
  padding-top: 1%;
}

.dealers_title {
  font-size: 60px;
  margin-bottom: 20px;
}

.line__separator {
  width: 210px;
  height: 1px;
  background-color: black;
  margin-bottom: 40px;
}

.columns_wrapper {
  display: flex;
  justify-content: flex-start;
}

.single_dealer {
  margin: 12% auto;
}

.dealer {
  color: gray;
  margin: 5px auto;
  font-size: 19px;
}

.contact_name {
  font-weight: 700;
  margin-top: 7px;
  font-size: 19px;
  color: black;
  text-decoration: none;
}

.contact_name_blue{
  font-weight: 700;
  margin-top: 7px;
  font-size: 19px;
  color: #006aca;
  text-decoration: underline;
}

/* Ipad */
@media only screen and (min-width: 686px) and (max-width: 1200px) {
  .columns_wrapper .column_spacer {
    width: 10% !important;
  }
}
/* Mobile */
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .dealers_title {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .dealers_wrapper .line__separator {
    width: 300px;
  }

  .dealer {
    font-size: 17px;
  }

  .contact_name {
    font-size: 17px;
  }

  .columns_wrapper {
    flex-direction: column;
  }

  .single_dealer {
    margin: 7% auto;
  }
}
