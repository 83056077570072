.footer__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 7%;
  height: 150px;

  .footer_buttons_wrapper {
    display: flex;

    .back_home {
      background-color: white;
      color: black;
      border: 1px solid black;
    }
  }

  .constellation__logo {
    a button {
      width: 310px;
      margin-right: 30px;
      background-color: #46c2ff;
      color: white;
      font-weight: 400;
      padding: 20px 25px;
      font-size: 22px;
      border-radius: 10px;
      border-color: black;
      cursor: pointer;
      border: none;
      margin-bottom: 15px;
    }
  }

  .social_icons {
    display: flex;
    justify-content: flex-end;

    .single_icon {
      margin: auto 10%;
    }
  }
}

.social_icons_links {
  text-decoration: none;
  color: black;
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .footer__wrapper {
    margin-top: 20px;
    margin-bottom: 30px;
    flex-direction: column;
    height: 200px;

    .footer_buttons_wrapper {
      display: block;

      .constellation__logo {
        padding-bottom: 15px;
      }
    }

    .constellation__logo {
      a button {
        font-size: 20px;
        margin-right: 0px;
      }
    }

    .social_icons {
      justify-content: center;
    }
  }
}
