@mixin line-separator($color) {
  width: 380px;
  height: 1px;
  background-color: $color;
  margin-bottom: 40px;
}

.container__local_dealer {
  background-color: #1f6aca;
  color: white;
  padding-left: 8%;
  padding-bottom: 5%;
  .container__info {
    padding-top: 5%;
    width: 70%;
    h1 {
      font-size: 52px;
      font-weight: normal;
    }
    .line__separator {
      @include line-separator(white);
    }
    p {
      font-size: 28px;
      font-weight: 600;
      line-height: 1.5;
    }
  }
  .container__icon__text {
    padding-top: 20px;
    display: flex;
    flex-wrap: wrap;
    .icon__text {
      display: flex;
      align-items: center;
      width: 50%;
      margin-bottom: 50px;
      .text {
        font-size: 22px;
        margin-left: 25px;
        line-height: 1.5;
      }
    }
  }
  .container__footer {
    .line__separator {
      @include line-separator(lightblue);
    }
    p {
      font-size: 28px;
      font-weight: 600;
      line-height: 1.5;
    }
    button {
      background-color: #46c2ff;
      color: white;
      font-weight: bold;
      padding: 25px;
      font-size: 23px;
      border-radius: 10px;
      border-color: black;
      cursor: pointer;
      border: none;
    }
  }
}

@media only screen and (min-width: 481px) and (max-width: 1100px) {
  .container__local_dealer {
    .container__info {
      padding-top: 5%;
      width: 90%;
      h1 {
        font-size: 36px;
      }
      .line__separator {
        @include line-separator(white);
        width: 310px;
      }
      p {
        font-size: 26px;
      }
    }
    .container__icon__text {
      padding-top: 20px;
      display: flex;
      flex-wrap: wrap;
      .icon__text {
        display: flex;
        align-items: center;
        font-size: 22px;
        width: 100%;
        margin-bottom: 50px;
        .text {
          padding-left: 15px;
          padding-right: 30px;
        }
      }
    }
    .container__footer {
      .line__separator {
        @include line-separator(lightblue);
        width: 310px;
      }
      p {
        font-size: 24px;
      }
      button {
        width: 90%;
      }
    }
  }
}
// mobile
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .container__local_dealer {
    padding-bottom: 50px;
    .container__info {
      padding-top: 8%;
      width: 90%;
      h1 {
        font-size: 32px;
      }
      .line__separator {
        @include line-separator(white);
        width: 310px;
      }
      p {
        font-size: 22px;
        line-height: 1.3;
      }
    }
    .container__icon__text {
      padding-top: 60px;
      display: flex;
      flex-wrap: wrap;
      .icon__text {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 50px;
        .text {
          padding-left: 15px;
          padding-right: 30px;
          font-size: 21px;
          font-weight: 400;
        }
      }
    }
    .container__footer {
      .line__separator {
        @include line-separator(lightblue);
        width: 310px;
      }
      p {
        font-size: 24px;
      }
      button {
        width: 90%;
        font-size: 16px;
      }
    }
  }
}
