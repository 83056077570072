.stats_container {
  display: flex;
  justify-content: space-evenly;
  background-color: #1f6aca;
  color: white;
  .stat_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3% 0px;
    font-weight: 600;
    .stat_count {
      font-size: 57px;
      margin: 2px auto;
    }
    .stat_name {
      font-size: 17px;
    }
  }
}

/* Mobile */
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .stats_container {
    align-items: center;
    flex-direction: column;
    padding: 6% 0px;
    .stat_box {
      width: 180px;
      padding: 3%;
      .stat_count {
        font-size: 42px;
      }
      .stat_name {
        text-align: center;
      }
    }
  }
}
