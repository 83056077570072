.articleSources_wrapper {
  background-color: #ececec;
  padding: 5% 0;
  .article_links {
    display: flex;
    width: 60%;
    margin: 0 auto;
    flex-direction: column;
    ul {
      padding-left: 0px;
      li {
        margin: 2% auto;
        list-style-type: none;
        line-height: 1.5;
      }
    }
  }
  .article_sources_title {
    font-size: 25px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .articleSources_wrapper {
    .article_links{
      width: 100%;
      padding: 10%;
      a, li {
        word-wrap: break-word;
        font-size: 12px;
      }
    }
  } 
}