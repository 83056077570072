.dashboard_service_wrapper {
  display: flex;
  font-size: 19px;
  margin-top: -1%;
  margin-bottom: -5px;
  .dashboard_content {
    padding: 2% 7%;
    width: 50%;
    .dashboard_title {
      font-weight: 700;
      font-size: 20px;
    }
    a {
      text-decoration: none;
      color: #006aca;
    }
    button {
      font-size: 19px;
      width: 250px;
      border-radius: 10px;
      background-color: #1f6aca;
      font-weight: bold;
      color: white;
      padding: 20px;
      border: none;
    }
  }
  .dashboard_image_wrapper {
    width: 50%;
    .dashboard_img {
      width: 100%;
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 1180px) {
  .dashboard_service_wrapper {
    .dashboard_image_wrapper {
      display: flex;
      align-items: center;
      margin-right: 1%;
    }
  }
}

/* Mobile */
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .dashboard_service_wrapper {
    flex-direction: column;
    .dashboard_content {
      width: 100%;
      text-align: left;
      button {
        width: 100%;
        margin-bottom: 6%;
      }
    }
    .dashboard_image_wrapper {
      width: 100%;
      text-align: center;
      margin-bottom: 7%;
    }
  }
}
