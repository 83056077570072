.container__about__us {
  background-color: white;
  padding-left: 7%;
  display: flex;
  height: 320px;
  .section__our__story {
    width: 45%;
    padding-right: 15%;
    padding-top: 7%;
    .story__title {
      font-size: 48px;
    }
    p {
      font-size: 18px;
      line-height: 30px;
    }
  }
  .section__video__story {
    width: 70%;
    .container__video {
      .play__icon {
        width: 100px;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .section__mobile {
      display: none;
    }
    .overlay {
      position: fixed;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 2;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding-top: 50px;
      padding-left: 50px;
      padding-right: 50px;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 820px) {
  .container__about__us {
    height: auto;
    flex-wrap: wrap;
    padding-bottom: 50px;
    .section__our__story {
      width: 100%;
    }
    .section__video__story {
      width: 90%;
      .section__desktop {
        display: none;
      }
      .section__mobile {
        display: block;
        .play__icon {
          width: 100px;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}
