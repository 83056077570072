.dashboard_service_page_wrapper {
  .hero_wrapper {
    color: white;
    position: relative;
    .mobile {
      display: none;
    }
    .hero_content {
      position: absolute;
      color: white;
      font-size: 70px;
      top: 31%;
      left: 7%;
    }
    .desktop {
      width: 100%;
    }
  }
  .content {
    margin: 2% auto;
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 25px;
    line-height: 1.3;
    .content_img_desktop {
      width: 100%;
    }
    .content_img_mobile {
      display: none;
    }
  }
  .movement_section {
    background-color: black;
    display: flex;
    color: white;
    font-size: 25px;
    justify-content: center;
    padding: 4% 0px;
    .movement_content {
      width: 85%;
      display: flex;
      justify-content: center;
      .movement_image_content {
        width: 25%;
      }
      .movement_text {
        width: 60%;
      }
    }
  }
  .content_second {
    margin: 2% auto;
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 25px;
    line-height: 1.3;
  }
}

@media only screen and (min-width: 870px) and (max-width: 1160px) {
  .dashboard_service_page_wrapper {
    .hero_wrapper {
      .hero_content {
        top: 17%;
        font-size: 60px;
      }
    }
  }
}

@media only screen and (min-width: 481px) and (max-width: 869px) {
  .dashboard_service_page_wrapper {
    .hero_wrapper {
      .hero_content {
        top: 3%;
        font-size: 50px;
      }
    }
    .movement_section {
      .movement_content {
        justify-content: space-around;
        .movement_text {
          font-size: 21px;
        }
      }
    }
  }
}
/* Mobile */
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .dashboard_service_page_wrapper {
    .hero_wrapper {
      .desktop {
        display: none;
      }
      .mobile {
        display: block;
        width: 100%;
      }
      .hero_content {
        font-size: 39px;
        top: 20%;
        left: 5%;
      }
    }
    .content {
      .content_img_wrapper {
        .content_img_desktop {
          display: none;
        }
        .content_img_mobile {
          display: block;
          width: 100%;
        }
      }
    }
    .movement_section {
      padding: 9% 0px;
      .movement_content {
        flex-direction: column;
        .movement_image_content {
          width: 100%;
          text-align: center;
        }
        .movement_text {
          width: 100%;
        }
      }
    }
  }
}
