@mixin button-style($color) {
  font-size: 14px;
  border-radius: 10px;
  border-color: black;
  background-color: $color;
  font-weight: bold;
  color: white;
  padding: 20px;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
}

.container__intro {
  .intro__info {
    color: white;
    position: relative;

    img {
      width: 100%;
      height: auto;
    }

    .mobile {
      display: none;
    }

    .intro__text {
      width: 60%;
      font-size: 23px;
      font-weight: 600;
      line-height: 1.5;
      position: absolute;
      top: 100px;
      left: 7%;

      .logo {
        width: 600px;

        img {
          width: 100%;
          height: auto;
        }
      }

      .container__slogan {
        display: flex;
        margin-top: 40px;
        margin-bottom: 15px;

        .heart__logo {
          width: 40px;
          margin-right: 10px;

          img {
            width: 100%;
            height: auto;
          }
        }

        .slogan__text {
          color: #fa5857;
          font-size: 28px;
        }
      }

      .container__buttons {
        width: 110%;
        display: flex;

        .color__blue {
          button {
            font-size: 19px;
            width: 270px;
            border-radius: 10px;
            border-color: black;
            background-color: #1f6aca;
            font-weight: bold;
            color: white;
            padding: 20px 20px;
            margin-right: 25px;
            margin-top: 10px;
            cursor: pointer;
            border: none;
          }
        }
      }
      .overlay {
        position: fixed;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 2;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding-top: 50px;
        padding-left: 50px;
        padding-right: 50px;
      }
    }
  }
}

@media only screen and (min-width: 481px) and (max-width: 1300px) {
  .container__intro .intro__info img {
    width: 100%;
    height: 600px;
  }

  .container__intro {
    .intro__info {
      .intro__text {
        width: 90%;
      }
    }
  }
}

@media only screen and (min-width: 481px) and (max-width: 820px) {
  .container__intro {
    .intro__info {
      .intro__text {
        width: 90%;
        font-size: 17px;
        font-weight: 500;
        line-height: 1.5;
        position: absolute;
        top: 10px;
        left: 7%;
        p {
          margin-top: 0;
        }

        .logo {
          width: 350px;
        }

        .container__slogan {
          margin-top: 5px;
          margin-bottom: 0px;

          .heart__logo {
            width: 25px;
            margin-right: 10px;
          }

          .slogan__text {
            color: #fa5857;
            font-size: 18px;
          }
        }

        .container__buttons {
          .color__blue {
            button {
              font-size: 18px;
              border-radius: 10px;
              border-color: black;
              background-color: #1f6aca;
              font-weight: bold;
              color: white;
              padding: 15px 20px;
              margin-right: 30px;
              margin-top: 10px;
              cursor: pointer;
              border: none;
            }
          }
        }
      }
    }
  }
}

// mobile
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .container__intro {
    .intro__info {
      img {
        width: 100%;
        height: auto;
        max-width: unset;
      }

      .desktop {
        display: none;
      }

      .mobile {
        display: block;
      }

      .intro__text {
        width: 85%;
        top: 98px;
        line-height: 3.5;
        left: 7%;

        .logo {
          width: 320px;
        }

        p {
          line-height: 26px;
          font-size: 20px;
          margin-top: 0px;
          margin-bottom: 20px;
        }

        .container__slogan {
          margin-top: -20px;
          margin-bottom: -15px;
          align-items: center;

          .heart__logo {
            width: 40px;
            margin-right: 10px;

            img {
              width: 100%;
              height: auto;
            }
          }

          .slogan__text {
            color: #fa5857;
            font-size: 26px;
          }
        }

        .container__buttons {
          width: 100%;
          display: flex;
          flex-direction: column;

          .color__blue {
            button {
              width: 100%;
              font-size: 18px;
              padding: 20px 35px 20px 35px;
            }
          }
        }
      }
    }
  }
}
