.container__loan {
  background-color: #ececec;
  padding: 4% 7%;
  .content__loan {
    margin-bottom: 50px;
    .content__title {
      font-size: 40px;
    }
    .line_separator {
      width: 210px;
      height: 1px;
      background-color: black;
      margin-bottom: 40px;
    }
    .content__text {
      font-size: 22px;
      margin: 30px auto;
    }
  }
  .container__logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .box__logo {
      border: 1px solid white;
      margin: auto 0.2%;
      text-align: center;
      align-items: center;
      background-color: white;
      width: 300px;
      height: 340px;
      margin-bottom: 25px;
      a {
        .logo__image {
          width: 200px;
          margin: 0 auto;
          img {
            width: 100%;
            height: auto;
          }
        }
        .jaguar {
          margin-top: 35px;
        }
      }
      .loanSupport_logo {
        width: 70%;
        padding-top: 20%;
        max-height: 165px;
      }
      .logo_line {
        margin: 0 auto;
        width: 210px;
        height: 1px;
        background-color: grey;
        margin-top: 15px;
      }
      .dealer_name {
        font-size: 18px;
        margin: 15px auto;
      }
      .dealer__text {
        font-size: 14px;
        color: gray;
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }
}

/* includes Ipad */
@media only screen and (min-width: 481px) and (max-width: 870px) {
  .container__loan {
    .container__logos {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      .box__logo {
        border: 1px solid white;
        margin: auto 0.2%;
        text-align: center;
        align-items: center;
        background-color: white;
        width: 250px;
        margin-bottom: 25px;
      }
    }
  }
}

/* Mobile */
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .container__loan {
    .content__loan {
      padding-left: 1%;
    }
    .container__logos {
      .box__logo {
        width: 150px;
        height: 320px;
        margin-right: 10px;
        a {
          .logo__image {
            width: 130px;
          }
        }
        .logo_line {
          width: 120px;
        }
      }
    }
  }
}
