.features_container {
  display: flex;
  flex-direction: column;
  padding: 4% 7%;
}

.features_title {
  font-size: 50px;
  margin-bottom: 20px;
}

.line_separator {
  width: 400px;
  height: 1px;
  background-color: black;
  margin-bottom: 30px;
}

.features_content {
  display: flex;
}

.read_more {
  color: rgba(0, 106, 202, 1);
  text-decoration: underline;
  font-size: 22px;
}

.features_video {
  // width: 150%;
  width: 50%;
}

.text {
  font-size: 32px;
  font-weight: 300;
  padding-right: 200px;
  line-height: 1.3;
}

@media only screen and (min-width: 481px) and (max-width: 820px) {
  .features_container {
    .features_content {
      flex-wrap: wrap;
      .text {
        padding-right: 0;
        font-size: 28px;
      }
      .features_video {
        width: 100%;
      }
    }
  }
}
/* Mobile */
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .features_content {
    flex-direction: column;
  }

  .features_title {
    font-size: 30px;
  }

  .features_container .line_separator {
    width: 100%;
    margin-bottom: 25px;
  }

  .features_video {
    width: 100%;
    height: 180px;
    margin: 17px 0 17px 0;
  }
  .text {
    font-size: 24px;
    font-weight: 400;
    padding-right: 0;
    line-height: 1.3;
  }
}
