/* Article hero images*/

.article_heroimg_customerProtection {
  background-image: url("https://static.dogoodautocoalition.com/Website/DGAC/article+pages/customer-protection/customer_service1.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  padding-bottom: 27%;
}

.article_heroimg_nytough {
  background-image: url("https://static.dogoodautocoalition.com/Website/DGAC/article+pages/nytough/features_NYstrong_header.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  padding-bottom: 27%;
}

.article_heroimg_virtualcarbuying {
  background-image: url("https://static.dogoodautocoalition.com/Website/DGAC/article+pages/virtual_buying/virtual_buying1.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  padding-bottom: 27%;
}

.article_heroimg_sanitizecar {
  background-image: url("https://static.dogoodautocoalition.com/Website/DGAC/article+pages/sanitize+car/sanitize_car1.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  padding-bottom: 27%;
}

.article_content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
  width: 60%;
  text-align: left;

  .article_main_title {
    font-size: 50px;
    margin-bottom: 30px;
  }

  .line__separator {
    width: 50%;
    height: 1px;
    background-color: black;
    margin-bottom: 40px;
  }

  .article_subtitle {
    font-size: 25px;
    color: rgba(0, 106, 202, 1);
    margin-bottom: 2px;
  }

  .article_title {
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 2px;
  }

  .article_paragraph {
    font-size: 25px;
    line-height: 1.3;
  }

  .article_video {
    width: 100%;
  }

  .article_img {
    width: 100%;
  }

  li {
    line-height: 1.7;
  }
}

@media only screen and (min-width: 714px) and (max-width: 875px) {}

/* Mobile */
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .article_heroimg_customerProtection {
    background-image: url("https://static.dogoodautocoalition.com/Website/DGAC/article+pages/customer-protection/CustomerProtection_Mobile_v1%402x_Hero.jpg");
    padding-bottom: 68%;
  }

  .article_heroimg_virtualcarbuying {
    background-image: url("https://static.dogoodautocoalition.com/Website/DGAC/article+pages/virtual_buying/DGAC_Article_VirtualBuying_Mobile_v1%402x_Hero.jpg");
    padding-bottom: 68%;
  }

  .article_heroimg_sanitizecar {
    background-image: url("https://static.dogoodautocoalition.com/Website/DGAC/article+pages/sanitize+car/DGAC_Article_CarSanitize_Mobile_v1-2%402x_Hero.jpg");
    padding-bottom: 68%;
  }

  .article_content {
    width: 100%;
    padding: 7%;

    .article_main_title {
      font-size: 42px;
    }

    .article_subtitle {
      font-size: 27px;
    }

    .article_paragraph {
      font-size: 21px;
      width: 100%;
    }

    li {
      font-size: 20px;
    }
  }
}