@mixin line-styling($width) {
  width: $width;
  height: 1px;
  background-color: black;
  margin-bottom: 40px;
}

.social_responsibility_wrapper {
  background-color: #f4f4f4;
  padding: 4% 7%;
  .social_responsibility_title {
    font-size: 54px;
    margin-bottom: 20px;
    line-height: 1.3;
  }
  .line_separator {
    @include line-styling(360px);
  }
  .section__social__tiles {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .button_wrapper a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    button {
      margin-top: 15px;
      background-color: #46c2ff;
      color: white;
      font-weight: bold;
      padding: 25px 30px;
      font-size: 23px;
      border-radius: 10px;
      border-color: black;
      cursor: pointer;
      border: none;
    }
  }
}

/* Ipad */
@media only screen and (min-width: 1000px) and (max-width: 1300px) {
  .row_content {
    padding: 5%;
  }

  .row_title {
    font-size: 22px;
  }

  .row_text {
    font-size: 20px;
  }

  .social_responsibility_title {
    font-size: 33px;
  }
  .read_more_social {
    font-size: 21px;
  }

  .social_responsibility_wrapper {
    .social_responsibility_title {
      font-size: 33px;
    }
    .line_separator {
      @include line-styling(360px);
    }
    .section__social__tiles {
      .tile__social {
        .container__tile__info {
          .line_separator {
            @include line-styling(100%);
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 714px) and (max-width: 999px) {
  .row_content {
    padding: 4%;
  }

  .read_more_social {
    font-size: 16px;
  }

  .row_title {
    font-size: 16px;
  }

  .row_text {
    font-size: 16px;
  }
  .social_responsibility_wrapper {
    .social_responsibility_title {
      font-size: 33px;
    }
    .line_separator {
      @include line-styling(360px);
    }
    .section__social__tiles {
      justify-content: center;
      .tile__social {
        .container__tile__info {
          .line_separator {
            @include line-styling(100%);
            margin-bottom: 20px;
          }
          .tile__text {
            font-size: 20px;
            color: gray;
            margin-bottom: 30px;
          }
          a {
            button {
              background-color: Transparent;
              color: black;
              border-radius: 5px;
              border-color: black;
              border-style: solid;
              border-width: thin;
              padding: 13px 70px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 481px) and (max-width: 713px) {
  .row_content {
    padding: 3%;
  }

  .row_title {
    font-size: 15px;
  }

  .row_text {
    font-size: 13px;
  }

  .read_more_social {
    font-size: 14px;
  }

  .social_responsibility_wrapper {
    .social_responsibility_title {
      font-size: 33px;
    }
    .line_separator {
      @include line-styling(360px);
    }
    .section__social__tiles {
      justify-content: center;
      .tile__social {
        .container__tile__info {
          .line_separator {
            @include line-styling(100%);
            margin-bottom: 20px;
          }
          .tile__text {
            font-size: 20px;
            color: gray;
            margin-bottom: 30px;
          }
          a {
            button {
              background-color: Transparent;
              color: black;
              border-radius: 5px;
              border-color: black;
              border-style: solid;
              border-width: thin;
              padding: 13px 70px;
            }
          }
        }
      }
    }
  }
}
/* Mobile */
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .social_responsibility_wrapper {
    background-color: #f4f4f4;
    padding: 4% 7%;
    .social_responsibility_title {
      font-size: 54px;
      margin-bottom: 20px;
      line-height: 1.3;
    }
    .line_separator {
      @include line-styling(360px);
    }
    .section__social__tiles {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
    }
    .button_wrapper a {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      button {
        margin-top: 15px;
        background-color: #46c2ff;
        color: white;
        font-weight: bold;
        padding: 20px 25px;
        font-size: 20px;
        border-radius: 10px;
        border-color: black;
        cursor: pointer;
        border: none;
      }
    }
  }
}
