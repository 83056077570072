.sponsors_page_wrapper {
  padding: 3% 7%;
  background-color: #ececec;
  .single_sponsor {
    background-color: white;
    margin: 2% 0;
    padding: 3%;
    display: flex;
    .logo_content {
      width: 28%;
      display: flex;
      justify-content: center;
      margin-top: 23px;
      padding-right: 15px;
      a img {
        width: 100%;
        height: auto;
      }
    }
    .single_sponsor_content {
      width: 72%;
    }
    .sponsor_name {
      font-size: 24px;
      font-weight: 500;
      color: #484848;
    }
    .paragraph {
      font-size: 20px;
      color: #505050;
      line-height: 1.4em;
    }
  }
  .help_section {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3%;
    margin-top: 6%;
    .help_title {
      font-size: 24px;
      margin-bottom: 3%;
    }
  }
}

@media only screen and (min-width: 870px) and (max-width: 1200px) {
  .sponsors_page_wrapper {
    .single_sponsor {
      .logo_content {
        width: 40%;
      }
      .single_sponsor_content {
        width: 60%;
      }
    }
  }
}

@media only screen and (min-width: 482px) and (max-width: 869px) {
  .sponsors_page_wrapper {
    .single_sponsor {
      flex-direction: column;
      .logo_content {
        width: 100%;
        margin-bottom: 3%;
      }
      .single_sponsor_content {
        width: 100%;
        text-align: center;
      }
    }
  }
}
/* mobile */
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .sponsors_page_wrapper {
    .single_sponsor {
      flex-direction: column;
      margin: 8% 0;
      .logo_content {
        width: 100%;
        margin-bottom: 6%;
      }
      .single_sponsor_content {
        width: 100%;
        text-align: center;
        .sponsor_name {
          font-size: 26px;
        }
      }
    }
    .help_section {
      .help_title {
        font-size: 26px;
        margin-bottom: 8%;
      }
    }
  }
}
