.dealer_highlight_wrapper {
  .desktop {
    width: 100%;
  }
  .mobile {
    display: none;
  }
  .dealer_content {
    display: flex;
    justify-content: center;
    font-size: 21px;
    width: 90%;
    margin: 2% auto;
    .left_side {
      width: 30%;
      .line_separator {
        margin-bottom: 10px;
      }
      .dealer_highlight {
        font-size: 30px;
        margin-top: 20px;
      }
      .dealer_button {
        background-color: white;
        border: 1px solid black;
        padding: 12px 30px;
        color: black;
      }
    }
    .right_side {
      width: 60%;
    }
  }
  // .section__social__tiles{
  //     background-color: #ececec;
  // }
  .dealer_title {
    text-align: center;
    font-size: 30px;
    margin-top: 0px;
  }
  .social_responsibility_wrapper {
    background-color: #ececec;
    margin-top: 3%;
  }
}

/* Mobile */
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .dealer_highlight_wrapper {
    .desktop {
      display: none;
    }
    .mobile {
      display: block;
      width: 100%;
    }
    .dealer_content {
      flex-direction: column;
      .left_side {
        width: 100%;
        .dealer_button {
          width: 330px;
        }
      }
      .right_side {
        width: 100%;
        p {
          line-height: 1.3;
        }
      }
    }
    .social_responsibility_wrapper {
        margin-top: 10%;
      }
  }
}
