@mixin line-styling($width) {
  width: $width;
  height: 1px;
  background-color: black;
  margin-bottom: 40px;
}

.tile__social {
  width: 47%;
  min-width: 400px;
  margin-right: 30px;
  margin-bottom: 20px;
  img {
    width: 100%;
    min-height: 360px;
  }
  .play__icon {
    width: 100px;
    img {
      width: 100%;
      min-height: 10px;
      height: auto;
    }
  }
  .container__tile__info {
    background-color: white;
    padding: 7% 7%;
    h1 {
      margin-top: 0;
      height: 90px;
      color: rgba(0, 106, 202, 1);
      font-size: 30px;
      font-weight: 500;
    }
    .line_separator {
      @include line-styling(100%);
      margin-bottom: 20px;
    }
    .tile__text {
      font-size: 22px;
      color: gray;
      margin-bottom: 30px;
      height: 130px;
    }
    a {
      button {
        background-color: Transparent;
        color: black;
        border-radius: 5px;
        border-color: black;
        border-style: solid;
        border-width: thin;
        padding: 13px 70px;
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
}

/* Ipad */
@media only screen and (min-width: 1000px) and (max-width: 1300px) {
  .tile__social {
    .container__tile__info {
      .line_separator {
        @include line-styling(100%);
        margin-bottom: 20px;
      }
    }
  }
}

@media only screen and (min-width: 714px) and (max-width: 999px) {
  .tile__social {
    .container__tile__info {
      .line_separator {
        @include line-styling(100%);
        margin-bottom: 20px;
      }
      .tile__text {
        font-size: 20px;
        color: gray;
        margin-bottom: 30px;
      }
      a {
        button {
          background-color: Transparent;
          color: black;
          border-radius: 5px;
          border-color: black;
          border-style: solid;
          border-width: thin;
          padding: 13px 70px;
        }
      }
    }
  }
}

@media only screen and (min-width: 481px) and (max-width: 713px) {
  .tile__social {
    img {
      width: 100%;
      min-height: 100px;
      height: auto;
    }
    .container__tile__info {
      .line_separator {
        @include line-styling(100%);
        margin-bottom: 20px;
      }
      .tile__text {
        font-size: 20px;
        color: gray;
        margin-bottom: 30px;
      }
      a {
        button {
          background-color: Transparent;
          color: black;
          border-radius: 5px;
          border-color: black;
          border-style: solid;
          border-width: thin;
          padding: 13px 70px;
        }
      }
    }
  }
}

/* Mobile */
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .tile__social {
    margin-right: 0px;
    min-width: 360px;
    img {
      width: 100%;
      min-height: 100px;
      height: auto;
    }
    .container__tile__info {
      h1 {
        height: 115px;
        font-size: 27px;
      }

      .line_separator {
        @include line-styling(100%);
        margin-bottom: 20px;
      }
      .tile__text {
        font-size: 20px;
        color: gray;
        margin-bottom: 30px;
      }
      a {
        button {
          background-color: Transparent;
          color: black;
          border-radius: 5px;
          border-color: black;
          border-style: solid;
          border-width: thin;
          padding: 13px 70px;
          width: 100%;
        }
      }
    }
  }
}
